<template>
    <div class="modal" :class="{'is-active': opened}">
      <div class="modal-background"></div>
      <div class="modal-card my-modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Upload Promotions</p>
          <button class="delete" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <p class="title is-4">Select CSV file to update promotions</p>
          <p class="subtitle is-6">Retrieve <i>channel (Wayfair | Home Depot | Wayfair Canada | Home Depot Canada), wholesaleModel(create new) or promotionId(update existing), promotionPercentage, promotionPrice, startDate, endDate</i> columns from CSV file to create/update records in promotion table</p>
          
          <p>Example of creating new:</p>
          <pre class="mb-3">
channel,wholesaleModel,promotionPercentage,startDate,endDate
Wayfair,VA6517-IO,10,2024-02-17,2024-03-08
          </pre>

          <p>Example of updating existing:</p>
          <pre class="mb-3">
channel,promotionId,promotionPrice,startDate,endDate
Home Depot,1001,203.99,2024-02-17,2024-03-08
          </pre>

          <div class="field has-addons">
            <div class="control">
              <div class="file has-name">
                <label class="file-label">
                  <input class="file-input" type="file" accept=".csv" @change="onFileChange">
                  <span class="file-cta">
                    <span class="file-icon">
                      <i class="fas fa-upload"></i>
                    </span>
                  </span>
                  <span class="file-name">
                    {{file ? file.name : 'Choose a CSV file...'}}
                  </span>
                </label>
              </div>
            </div>
            <div class="control">
              <button class="button" :class="{'is-loading': waiting}" :disabled="!file" @click="uploadPrices">
                Upload Promotions
              </button>
            </div>
          </div>
  
          <div v-if="success" class="notification is-success is-light">
            <button class="delete" @click="success=''"></button>
            <div><strong>{{success}}</strong></div>
          </div>
  
          <div v-if="successes.length" class="notification is-success is-light">
            <div>
              <strong>Updated successfully: {{successes.length}}</strong>
              <br/>
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <td>promotionId</td>
                    <td>promotionPercentage</td>
                    <td>promotionPrice</td>
                    <td>startDate</td>
                    <td>endDate</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(s, i) in successes" :key="'s-'+i">
                    <td>{{s.id}}</td>
                    <td>{{s.percentage}}</td>
                    <td>{{s.price}}</td>
                    <td>{{s.startDate}}</td>
                    <td>{{s.endDate}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  
          <div v-if="failures.length" class="notification is-danger is-light">
            <div>
              <strong>Failed to update: {{failures.length}}</strong>
              <br/>
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <td>wholesaleModel</td>
                    <td>Reason</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(s, i) in failures" :key="'f-'+i">
                    <td>{{s.wholesaleModel}}</td>
                    <td>{{s.reason}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  
          <div v-if="error" class="notification is-danger is-light">
            <button class="delete" @click="error=''"></button>
            {{error}}
          </div>
        </section>
        <footer class="modal-card-foot">
          <a class="button" @click="close">Close</a>
        </footer>
      </div>
    </div>
  </template>
    
  <script>
  import { parse } from 'csv-parse/lib/sync'
  import dateFormat from 'dateformat'
  import { saveAs } from 'file-saver'
   
  export default {
    name: 'upload-promotion-modal',
    props: ['opened'],
    data () {
      return {
        error: '',
        waiting: false,
        file: null,
        fieldNames: ['channel', 'wholesaleModel', 'promotionId', 'promotionPercentage', 'promotionPrice', 'startDate', 'endDate'],
        success: '',
        successes: [],
        failures: [],
      }
    },
    computed: {
      server () {
        return this.$store.state.config.server
      },
    },
    methods: {
      close () {
        this.$emit('closeUploadPromotionModal')
      },
      onFileChange (e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.file = files[0];
      },
      uploadPrices () {
        if (!this.file || this.waiting) {
          return
        }
        this.waiting = true
        this.error = ''
  
        var vm = this
        var reader = new FileReader()
        reader.onload = function () {
          var text = reader.result
          var records = parse(text, {
            columns: true,
          })
          vm.sendUpdates(records)
        }
        reader.readAsText(this.file)
        
      },
      sendUpdates (records) {
        var batches = []
        var batch = []
        for (var record of records) {
          if (record['wholesaleModel'] || record['promotionId']) {
            var update = {}
            for (const fieldName of this.fieldNames) {
              if (record[fieldName]) {
                update[fieldName] = record[fieldName].trim()
              }
            }
            batch.push(update)
            if (batch.length == 100) {
              batches.push(batch.slice())
              batch = []
            }
          }
        }
        if (batch.length) {
          batches.push(batch)
        }
  
        var promises = []
        this.successes = []
        this.failures = []
        this.success = ''
        this.error = ''
        for (var updates of batches) {
          var message = {updates: updates}
          var promise = this.$http.post(this.server + '/myapp/batch-update-promotions/', message).then(resp => {
            this.successes = this.successes.slice().concat(resp.body.successes)
            this.failures = this.failures.slice().concat(resp.body.failures)
          }, err => {
            console.log('One update request failed', err)
            this.error = 'Some upload failed.'
          })
          promises.push(promise)
        }
  
        Promise.all(promises).then(resp => {
          this.success = 'Process has been finished.'
          this.waiting = false
          this.exportPromotions()
        }, err => {
          this.error = 'Some uploads failed.'
          this.waiting = false
        })
      },
      exportPromotions () {
        var header = ['promotionId', 'promotionPercentage', 'promotionPrice', 'startDate', 'endDate']
        var rows = [header.join(',')]
        for (var promotion of this.successes) {
            var row = [promotion.id.toString(), promotion.percentage.toString(), promotion.startDate, promotion.endDate]
            rows.push(row.join(','))
        }
        var string = rows.join('\r\n')
        var blob = new Blob([string], {type: 'text/plain;charset=utf-8'})
        var date = dateFormat(Date.now(), 'isoDate')
        saveAs(blob, 'promotions_' + date + '.csv')
      },
    },
  }
  </script>
    
  <style scoped>
  .my-modal-card {
    width: 80%!important;
  }
  </style>