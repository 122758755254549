<template>
   <div class="modal" :class="{'is-active': opened}">
     <div class="modal-background"></div>
     <div class="modal-card">
       <header class="modal-card-head">
         <p class="modal-card-title">Export Multiple days Prices</p>
         <button class="delete" @click="close"></button>
       </header>
       <section class="modal-card-body">
        <div class="field">
          <label class="label">Export Values</label>
          <div class="control">
              <div class="select">
                <select v-model="exportValue">
                  <option :value="'price'">Price</option>
                  <option :value="'availableAmount'">Inventory</option>
                  <option :value="'nsInventoryAmount'">NS Inventory</option>
                  <option :value="'nsCaInventoryAmount'">NS CA Inventory</option>
                  <option :value="'nsNyInventoryAmount'">NS NY Inventory</option>
                  <option :value="'nsTxInventoryAmount'">NS TX Inventory</option>
                </select>
              </div>
          </div>
        </div>

        <div class="field" v-if="exportValue == 'price' || exportValue == 'availableAmount'">
          <label class="label">Channel</label>
          <div class="control">
              <div class="select">
                <select v-model="channel">
                  <option :value="1">Home Depot</option>
                  <option :value="2">Wayfair</option>
                  <option :value="3">Overstock</option>
                  <option :value="4">Amazon</option>
                  <option :value="5">Lowes</option>
                </select>
              </div>
          </div>
        </div>

         <div class="field">
           <label class="label">Select Date Range</label>
           <div class="control">
            <div class="columns">
              <div class="column from-date-picker">
                <datepicker v-model="fromDate" :inline="true" name="fromdate"></datepicker>
              </div>
              <div class="column to-date-picker">
                <datepicker v-model="toDate" :inline="true" name="todate"></datepicker>
              </div>
            </div>
           </div>
         </div>
         <div v-if="error" class="notification is-danger is-light">
           <button class="delete" @click="error=''"></button>
           {{error}}
         </div>
       </section>
       <footer class="modal-card-foot">
         <a class="button is-link" :class="{'is-loading': waiting}" :disabled="!fromDate || !toDate" @click="exportPrices">Export</a>
         <a class="button" @click="close">Cancel</a>
       </footer>
     </div>
   </div>
</template>
 
<script>
import Datepicker from 'vuejs-datepicker'
import dateFormat from 'dateformat'
import { saveAs } from 'file-saver'

export default {
  name: 'export-multiple-days-modal',
  components: {
    Datepicker
  },
  props: ['opened', 'showingProducts'],
  data () {
    return {
      error: '',
      waiting: false,
      exportValue: 'availableAmount',
      channel: 1,
      fromDate: null,
      toDate: null,
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    productIds () {
      if (this.showingProducts) {
        return this.showingProducts.map(p => p.id)
      }
    },
    productMap () {
      if (this.showingProducts) {
        var productMap = {}
        for (const p of this.showingProducts) {
          productMap[p.id] = p
        }
        return productMap
      }
    },
  },
  methods: {
    close () {
      this.$emit('closeExportPricesModal')
    },
    exportPrices () {
      if (!this.fromDate || !this.toDate) {
        return
      }
      console.log(this.productIds)
      console.log(this.exportValue)
      console.log(this.channel)
      var fromDate = dateFormat(this.fromDate, 'isoDate')
      console.log(fromDate)
      var toDate = dateFormat(this.toDate, 'isoDate')
      console.log(toDate)
      
      
      this.waiting = true
      var message = {
        productIds: this.productIds,
        channel: this.channel,
        fromDate: dateFormat(this.fromDate, 'isoDate'),
        toDate: dateFormat(this.toDate, 'isoDate')
      }
      this.$http.post(this.server + '/myapp/find-product-prices/', message).then(resp => {
        this.processData(resp.body)
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    processData (data) {
      var vm = this
      var priceMap = {}
      for (const price of data) {
        if (!priceMap[price.productId]) {
          priceMap[price.productId] = {}
        }
        priceMap[price.productId][price.date] = price
      }

      var dates = []
      var date = vm.fromDate
      while (date.getTime() <= vm.toDate.getTime()) {
        dates.push(dateFormat(date, 'isoDate'))
        date = new Date(date.getTime() + (24 * 60 * 60 * 1000))
      }

      var rows = vm.productIds.map(pid => {
        var products = priceMap[pid];
        if (!products) {
          products = {}
        }
        var row = [
          vm.productMap[pid].model,
          vm.productMap[pid].productClass ? vm.productMap[pid].productClass : '',
          vm.productMap[pid].nsSku ? vm.productMap[pid].nsSku : '',
          vm.productMap[pid].nsIdIndex ? vm.productMap[pid].nsIdIndex : ''
        ]
        for (const date of dates) {
          if (products[date]) {
            var value = products[date][vm.exportValue]
            row.push(value == null ? '' : value.toString())
          } else {
            row.push('')
          }
        }
        return row.join('\t')
      })

      var header = 'Model\tClass\tNS Sku\tNS Id Index\t' + dates.join('\t')
      rows.unshift(header)
      var string = rows.join('\r\n')
      var blob = new Blob([string], {type: 'text/plain;charset=utf-8'})
      var filename = dateFormat(vm.fromDate, 'isoDate') + '_' + dateFormat(vm.toDate, 'isoDate') + '_' + vm.channel + '_' + vm.exportValue
      saveAs(blob, filename + '.tsv')
    },
  },
}
</script>
 
<style scoped>
.my-datepicker {
  height: 400px;
}

.from-date-picker {
  padding-right: 0px;
}
.to-date-picker {
  padding-left: 0px;
}
</style>