<template>
  <div class="modal" :class="{'is-active': opened}">
    <div class="modal-background"></div>
    <div class="modal-card my-modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Upload Wholesale Prices</p>
        <button class="delete" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <p class="title is-4">Select CSV file to update wholesale prices</p>
        <p class="subtitle is-6">Retrieve <i>channel (Wayfair | Home Depot | Wayfair Canada | Home Depot Canada), wholesaleModel, wholesalePrice, startDate</i> columns from CSV file to create/update records in wholesale price table</p>

        <p>Example:</p>
        <pre class="mb-3">
channel,wholesaleModel,wholesalePrice,startDate
Wayfair,VA6813-L,499,2023-11-10
Home Depot,VA2048-E,752.21,2023-11-10
        </pre>

        <div class="field has-addons">
          <div class="control">
            <div class="file has-name">
              <label class="file-label">
                <input class="file-input" type="file" accept=".csv" @change="onFileChange">
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                </span>
                <span class="file-name">
                  {{file ? file.name : 'Choose a CSV file...'}}
                </span>
              </label>
            </div>
          </div>
          <div class="control">
            <button class="button" :class="{'is-loading': waiting}" :disabled="!file" @click="uploadPrices">
              Upload Prices
            </button>
          </div>
        </div>

        <div v-if="success" class="notification is-success is-light">
          <button class="delete" @click="success=''"></button>
          <div><strong>{{success}}</strong></div>
        </div>

        <div v-if="successes.length" class="notification is-success is-light">
          <div>
            <strong>Updated successfully: {{successes.length}}</strong>
            <br/>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <td>wholesaleModel</td>
                  <td>Result</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(s, i) in successes" :key="'s-'+i">
                  <td>{{s.wholesaleModel}}</td>
                  <td>{{s.reason}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="failures.length" class="notification is-danger is-light">
          <div>
            <strong>Failed to update: {{failures.length}}</strong>
            <br/>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <td>wholesaleModel</td>
                  <td>Reason</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(s, i) in failures" :key="'f-'+i">
                  <td>{{s.wholesaleModel}}</td>
                  <td>{{s.reason}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="error" class="notification is-danger is-light">
          <button class="delete" @click="error=''"></button>
          {{error}}
        </div>
      </section>
      <footer class="modal-card-foot">
        <a class="button" @click="close">Close</a>
      </footer>
    </div>
  </div>
</template>
  
<script>
import { parse } from 'csv-parse/lib/sync'
import dateFormat from 'dateformat'
import { saveAs } from 'file-saver'
 
export default {
  name: 'upload-wholesale-modal',
  props: ['opened'],
  data () {
    return {
      error: '',
      waiting: false,
      file: null,
      fieldNames: ['channel', 'wholesaleModel', 'wholesalePrice', 'startDate'],
      success: '',
      successes: [],
      failures: [],
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
  },
  methods: {
    close () {
      this.$emit('closeUploadWholesaleModal')
    },
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.file = files[0];
    },
    uploadPrices () {
      if (!this.file || this.waiting) {
        return
      }
      this.waiting = true
      this.error = ''

      var vm = this
      var reader = new FileReader()
      reader.onload = function () {
        var text = reader.result
        var records = parse(text, {
          columns: true,
        })
        vm.sendUpdates(records)
      }
      reader.readAsText(this.file)
      
    },
    sendUpdates (records) {
      var batches = []
      var batch = []
      for (var record of records) {
        if (record['wholesaleModel']) {
          var update = {}
          for (const fieldName of this.fieldNames) {
            if (record[fieldName]) {
              update[fieldName] = record[fieldName].trim()
            }
          }
          batch.push(update)
          if (batch.length == 100) {
            batches.push(batch.slice())
            batch = []
          }
        }
      }
      if (batch.length) {
        batches.push(batch)
      }

      var promises = []
      this.successes = []
      this.failures = []
      this.success = ''
      this.error = ''
      for (var updates of batches) {
        var message = {updates: updates}
        var promise = this.$http.post(this.server + '/myapp/batch-update-wholesale-prices/', message).then(resp => {
          this.successes = this.successes.slice().concat(resp.body.successes)
          this.failures = this.failures.slice().concat(resp.body.failures)
        }, err => {
          this.error = 'Some uploads failed.'
          console.log('One update request failed', err)
        })
        promises.push(promise)
      }

      Promise.all(promises).then(resp => {
        this.success = 'Process has been finished.'
        this.waiting = false
      }, err => {
        this.error = 'Some uploads failed.'
        this.waiting = false
      })
    },
  },
}
</script>
  
<style scoped>
.my-modal-card {
  width: 80%!important;
}
</style>